// Banner.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Banner.css'; // Assuming you'll create CSS for the banner

const Banner = () => {
  return (
    <Container fluid className="banner">
      <Row className="align-items-center">
        <Col md={6} className="banner-text">
          <h1>Welcome to <span className="highlight">Codingboss</span></h1>
          <p>
           <b>Unlock your potential and master your coding skills with us!<br />
              <span className="highlight">Codingboss</span> is the ultimate platform for honing your expertise through challenging MCQ and programming tests. Whether you’re gearing up for exams or simply aiming to sharpen your skills, our tests are designed to push you further and help you grow. Dive in, explore, and start your journey to becoming a coding pro with Codingboss—your gateway to success!
            </b>
          </p>
          <Link to="/LoginPage">
            <Button variant="warning" className="create-test-btn"><b>Start Challenges</b></Button>
          </Link>
          <div className="banner-icons">
            <span>Just Click to Start your journey</span>
          </div>
        </Col>
        <Col md={3} className="banner-image">
          <img src="https://www.testportal.net/img/1276x1684/958802f17f/cta-v1-en-business-excel.png/m/828x0/filters:quality(75):format(webp)" alt="Excel Test" />
        </Col>
        <Col md={3} className="banner-image">
          <img src="https://www.testportal.net/img/1276x1684/16690f87d8/cta-v1-en-business-scrum.png/m/828x0/filters:quality(75):format(webp)" alt="Scrum Test" />
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;
