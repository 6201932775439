import React, { useState, useEffect } from 'react';
import QuestionCard from './QuestionCard';
import './MCQQuiz.css';
import { useNavigate } from 'react-router-dom';

function MCQQuiz({ questions }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [timer, setTimer] = useState(questions.length * 60); // 1 min per question * total questions
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(interval);
          handleSubmit(); // Automatically submit when time runs out
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleAnswerSelect = (answer) => {
    const updatedAnswers = [...selectedAnswer];
    updatedAnswers[currentQuestionIndex] = answer;
    setSelectedAnswer(updatedAnswers);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = () => {
    const answers = selectedAnswer.map((answer, index) => ({
      questionID: questions[index].id,
      correctAnswer: answer,
    }));
    // Send the answers to the API
    navigate('/UserDashboard');
    // console.log('Submitting answers:', answers);
    // You can also make a POST request to submit the answers
  };

  return (
    <div className="quiz-container">
      <div className="timer">{`${Math.floor(timer / 60)}:${timer % 60}`}</div>
      <QuestionCard
        question={questions[currentQuestionIndex]}
        currentQuestionIndex={currentQuestionIndex}
        handleAnswerSelect={handleAnswerSelect}
        selectedAnswer={selectedAnswer}
      />
      <div className="navigation-buttons">
        <button onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
          Previous
        </button>
        <button onClick={handleNextQuestion} disabled={currentQuestionIndex === questions.length - 1}>
          Next
        </button>
      </div>
      <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
}

export default MCQQuiz;
