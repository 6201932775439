import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col, Spinner } from "react-bootstrap";
import Logo from "./images/Codingboss-logo-1.png";
import Navbar from './Navbar'
import apiClient from './utils/apiClient'

const CompanyCards = ({isLoggedIn, userRole, handleLogout, username }) => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

 useEffect(() => {
  
     if (!isLoggedIn) {
       navigate('/LoginPage')
     }
  
   const fetchCompanies = async () => {
     try {
       const data = await apiClient('quiz/companies/', 'GET', null, {
         'ngrok-skip-browser-warning': '98547',
       })
       setCompanies(data)
     } catch (err) {
       setError(
         err.message || 'Failed to fetch companies. Please try again later.'
       )
     } finally {
       setLoading(false)
     }
   }

   fetchCompanies()
 }, [])

  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>

    <Navbar 
            isLoggedIn={isLoggedIn} 
            username={username} 
            userRole={userRole} 
            handleLogout={handleLogout} 
          />

    <Row xs={1} sm={2} md={3} className="g-4">
      {companies.map((company) => (
        <Col key={company.id}>
          <Card className="h-100 shadow-sm">
            <Card.Img variant="top" src={Logo || "placeholder.jpg"} />
            <Card.Body>
              <Card.Title>{company.name}</Card.Title>
              <Card.Text>{company.description}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    </>
  );
};

export default CompanyCards;
