import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import TextField from '@mui/material/TextField';

const CalendarWrapper = styled.div`
  .fc {
    max-width: 100%;
    margin: 0 auto;
    color: #333;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
  }
`;

const CalendarComponent = ({
  events = [],
  isEditable = true,
  onDateBlock = () => {},
}) => {
  const [localEvents, setLocalEvents] = useState(events);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    CompanyName: '',
    programTitle: '',
    eventPlace: '',
    location: '',
    startDate: '',
    endDate: '',
    NoofDays: '',
    TableofContent: '',
  });
  const [nonWorkingDays, setNonWorkingDays] = useState([]);
  const [completedDays, setCompletedDays] = useState([]);
  const [tempCompletedDays, setTempCompletedDays] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {
    setLocalEvents(events);
  }, [events]);

  const calculateDateRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate).toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const handleDateClick = (info) => {
    if (!isEditable) {
      alert('You do not have permission to block dates.');
      return;
    }
    setFormValues({
      CompanyName: '',
      programTitle: '',
      eventPlace: '',
      location: '',
      startDate: info.dateStr,
      endDate: '',
      NoofDays: '',
      TableofContent: null,
    });
    setIsEventModalOpen(true);
  };

  const handleEventClick = (info) => {
    const clickedEvent = localEvents.find((event) => event.id === info.event.id);
    if (clickedEvent) {
      setSelectedEvent(clickedEvent);

      const range = calculateDateRange(clickedEvent.start, clickedEvent.end);

      setNonWorkingDays([]);
      setTempCompletedDays(clickedEvent.completedDays || []);
      setDateRange(range);
      setIsCompletedModalOpen(true);
    }
  };

  const handleAddToCompletedDays = (date) => {
    if (!tempCompletedDays.includes(date)) {
      setTempCompletedDays((prev) => [...prev, date]);
    }
  };

  const handleRemoveFromCompletedDays = (date) => {
    setTempCompletedDays((prev) => prev.filter((d) => d !== date));
  };

  const handleSaveCompletedDays = () => {
    setCompletedDays(tempCompletedDays);

    const updatedEvent = {
      ...selectedEvent,
      completedDays: tempCompletedDays,
    };

    setLocalEvents((prevEvents) =>
      prevEvents.map((event) => (event.id === updatedEvent.id ? updatedEvent : event))
    );

    alert(`Completed Days saved: ${tempCompletedDays.join(', ')}`);
    setIsCompletedModalOpen(false);
  };

  const handleEventModalSave = () => {
    if (!formValues.programTitle || !formValues.startDate || !formValues.endDate) {
      alert('Please fill all required fields.');
      return;
    }

    const newEvent = {
      id: String(localEvents.length + 1),
      title: formValues.programTitle,
      start: formValues.startDate,
      end: formValues.endDate,
      color: '#42A5F5',
      companyName: formValues.CompanyName,
      programTitle: formValues.programTitle,
      eventPlace: formValues.eventPlace,
      location: formValues.location,
      noOfDays: formValues.NoofDays,
      tableOfContent: formValues.TableofContent,
    };

    setLocalEvents([...localEvents, newEvent]);
    onDateBlock(newEvent);
    setIsEventModalOpen(false);
  };

  const handleEventModalClose = () => setIsEventModalOpen(false);
  const handleCompletedModalClose = () => setIsCompletedModalOpen(false);

  return (
    <CalendarWrapper>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth',
        }}
        events={localEvents}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        editable={isEditable}
      />

      {/* Event Modal */}
      <Modal show={isEventModalOpen} onHide={handleEventModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <TextField
              fullWidth
              label="Company Name"
              name="CompanyName"
              value={formValues.CompanyName}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  CompanyName: e.target.value,
                }))
              }
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Program Title"
              name="programTitle"
              value={formValues.programTitle}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  programTitle: e.target.value,
                }))
              }
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Event Place"
              name="eventPlace"
              value={formValues.eventPlace}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  eventPlace: e.target.value,
                }))
              }
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Location"
              name="location"
              value={formValues.location}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, location: e.target.value }))
              }
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              name="startDate"
              value={formValues.startDate}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  startDate: e.target.value,
                }))
              }
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
            />
            <TextField
              fullWidth
              label="End Date"
              type="date"
              name="endDate"
              value={formValues.endDate}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, endDate: e.target.value }))
              }
              margin="normal"
              InputLabelProps={{ shrink: true }}
              required
            />
            <TextField
              fullWidth
              label="No.of.Days"
              type="number"
              name="NoofDays"
              value={formValues.NoofDays}
              onChange={(e) =>
                setFormValues((prev) => ({ ...prev, NoofDays: e.target.value }))
              }
              margin="normal"
              required
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                margin: '16px 0',
                width: '100%',
              }}
            >
              <label
                htmlFor="upload-toc"
                style={{
                  fontSize: '1rem',
                  color: 'rgba(0, 0, 0, 0.6)',
                  marginBottom: '4px',
                }}
              >
                TOC
              </label>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  padding: '10px 12px',
                  backgroundColor: '#fff',
                  width: '100%',
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                <input
                  id="upload-toc"
                  type="file"
                  accept=".pdf,.doc,.docx,.txt"
                  onChange={(e) => {
                    const file = e.target.files[0]
                    if (file) {
                      setFormValues((prev) => ({
                        ...prev,
                        TableofContent: file,
                      }))
                    }
                  }}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer',
                  }}
                  required
                />
                <span style={{ flex: 1 }}>
                  {formValues.TableofContent
                    ? formValues.TableofContent.name
                    : 'Choose a file...'}
                </span>
                <button
                  type="button"
                  style={{
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    border: 'none',
                    padding: '6px 12px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                  }}
                >
                  Browse
                </button>
              </div>
              {formValues.TableofContent && (
                <p
                  style={{
                    marginTop: '8px',
                    fontSize: '0.875rem',
                    color: 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  Uploaded File: {formValues.TableofContent.name}
                </p>
              )}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEventModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleEventModalSave}>
            Save Event
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Completed Days Modal */}
      <Modal show={isCompletedModalOpen} onHide={handleCompletedModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Event Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Event Information</h5>
          {selectedEvent && (
            <div>
              <p>
                <strong>Company Name:</strong> {selectedEvent.companyName}
              </p>
              <p>
                <strong>Program Title:</strong> {selectedEvent.programTitle}
              </p>
              <p>
                <strong>Event Place:</strong> {selectedEvent.eventPlace}
              </p>
              <p>
                <strong>Location:</strong> {selectedEvent.location}
              </p>
              <p>
                <strong>No.of.Days:</strong> {selectedEvent.noOfDays}
              </p>
              <p>
                <strong>Table of Content:</strong>{' '}
                {selectedEvent.tableOfContent}
              </p>
            </div>
          )}
          <Form>
            <h5>Completed Days</h5>
            <ListGroup>
              {tempCompletedDays.map((date, index) => (
                <ListGroup.Item key={index}>
                  {date}
                  <Button
                    size="sm"
                    variant="danger"
                    style={{ float: 'right' }}
                    onClick={() => handleRemoveFromCompletedDays(date)}
                  >
                    Remove
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>

            <h5 className="mt-4">Non-Working Days</h5>
            <ListGroup>
              {dateRange.map((date) => (
                <ListGroup.Item key={date}>
                  {date}
                  {tempCompletedDays.includes(date) ? (
                    <Button
                      size="sm"
                      variant="danger"
                      style={{ float: 'right' }}
                      onClick={() => handleRemoveFromCompletedDays(date)}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="success"
                      style={{ float: 'right' }}
                      onClick={() => handleAddToCompletedDays(date)}
                    >
                      Add
                    </Button>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveCompletedDays}>
            Save
          </Button>
          <Button variant="secondary" onClick={handleCompletedModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </CalendarWrapper>
  )
};

export default CalendarComponent;
