const BASE_URL = 'https://api-dev.thiran360ai.com/';

const apiClient = async (endpoint, method = 'GET', body = null, customHeaders = {}) => {
  const url = `${BASE_URL}${endpoint}`;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': '98547',
    ...customHeaders,
  };

  const options = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json(); // Parse the JSON response
  } catch (error) {
    console.error('API call error:', error);
    throw error; // Rethrow for the calling code to handle
  }
};

export default apiClient;
