import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import  Navbar  from './Navbar';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Dashboard = ({ isLoggedIn ,userRole, handleLogout, username }) => {
    const location = useLocation();
    const studentData = location.state?.student;

    const [graphType, setGraphType] = useState('questions'); // default graph type

    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/LoginPage')
      }
    }, [])

    
    const dashboardContainerStyle = {
        textAlign: 'center',
        padding: '20px'
    };

    const studentDetailsStyle = {
        marginTop: '20px'
    };

    const infoCardsStyle = {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
        marginTop: '20px'
    };

    const infoCardStyle = {
        backgroundColor: '#f0f0f0',
        padding: '20px',
        borderRadius: '8px',
        width: '150px',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer'
    };

    const infoCardTitleStyle = {
        fontSize: '18px',
        marginBottom: '10px'
    };

    const infoCardTextStyle = {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '0'
    };

    // Dynamically generate data for the graph based on the selected card type
    const generateGraphData = () => {
        // Default dayData (questions solved)
        let dayData = {
            "day1": 9, "day2": 9, "day3": 9, "day4": 9, "day5": 9, 
            "day6": 9, "day7": 9, "day8": 9, "day9": 9, "day10": 9
        };

        // Update the dayData based on the selected graph type
        if (graphType === 'percentage') {
            dayData = {
                "day1": 80, "day2": 85, "day3": 90, "day4": 88, "day5": 84, 
                "day6": 92, "day7": 87, "day8": 93, "day9": 95, "day10": 91
            };
        } else if (graphType === 'assessments') {
            dayData = {
                "day1": 3, "day2": 2, "day3": 4, "day4": 5, "day5": 1, 
                "day6": 2, "day7": 3, "day8": 2, "day9": 1, "day10": 3
            };
        }

        const data = [];
        Object.keys(dayData).forEach((day) => {
            data.push({
                day: day,  // X-axis value
                value: dayData[day]  // Y-axis value (questions solved, percentage, assessments)
            });
        });

        return data;
    };

    const graphData = generateGraphData();

    return (

        <>

    <Navbar 
            isLoggedIn={isLoggedIn} 
            username={username} 
            userRole={userRole} 
            handleLogout={handleLogout} 
          />
        <div style={dashboardContainerStyle}>
            {studentData ? (
                <div style={studentDetailsStyle}>
                 <h2>{studentData?.name || "No Name Available"}</h2>
                    <div style={infoCardsStyle}>
                        <div style={infoCardStyle} onClick={() => setGraphType('percentage')}>
                            <h3 style={infoCardTitleStyle}><b>Average Score</b></h3>
                            <p style={infoCardTextStyle}>{studentData.percentage}</p>
                        </div>
                        <div style={infoCardStyle} onClick={() => setGraphType('questions')}>
                            <h3 style={infoCardTitleStyle}><b>Questions Solved</b></h3>
                            <p style={infoCardTextStyle}>{studentData.questions}</p>
                        </div>
                        <div style={infoCardStyle} onClick={() => setGraphType('assessments')}>
                            <h3 style={infoCardTitleStyle}><b>Pending Assessments</b></h3>
                            <p style={infoCardTextStyle}>{studentData.bending_assesments}</p>
                        </div>
                    </div>

                    {/* Graph Section */}
                    <div style={{ marginTop: '100px', backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                        <h3 style={{ textAlign: 'center' }}>
                            {graphType === 'questions' ? 'Questions Solved Over Days' :
                            graphType === 'percentage' ? 'Marks Percentage Over Days' :
                            'Assessments Pending Over Days'}
                        </h3>
                        <ResponsiveContainer width="100%" height={300}>
                            <AreaChart data={graphData}>
                                <defs>
                                    <linearGradient id="colorData" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="day" label={{ value: 'Days', position: 'insideBottom', offset: -5 }} />
                                <YAxis label={{ value: graphType === 'percentage' ? 'Percentage' : 'Count', angle: -90, position: 'insideLeft' }} />
                                <Tooltip />
                                <Area type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorData)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ) : (
                <p>No student data available</p>
            )}
        </div>
        </>
    );
};

export default Dashboard;
