import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Container, Row, Col, Dropdown, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import MonacoEditor from "@monaco-editor/react";
import './QuestionPage.css';
import  Navbar  from './Navbar';
import apiClient from './utils/apiClient'

const QuestionPage = ({ isLoggedIn ,userRole, handleLogout, username }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [sourceCode, setSourceCode] = useState('')
  const [stdin, setStdin] = useState('')
  const [expectedOutput, setExpectedOutput] = useState('')
  const [result, setResult] = useState('')
  const [error, setError] = useState(null)
  const [languages, setLanguages] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [passedCases, setPassedCases] = useState(0)
  const [totalCases, setTotalCases] = useState(0)
  const [isEditorLocked, setIsEditorLocked] = useState(false)
  const [output, setOutput] = useState('')
  const [showInputOutput, setShowInputOutput] = useState(false)
  const [showTestCases, setShowTestCases] = useState(false)
  const [isRunClicked, setIsRunClicked] = useState(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [userId, setUserId] = useState(localStorage.getItem('userid')) // State to hold user ID
  const editorRef = useRef(null)
  const [isAlertVisible, setIsAlertVisible] = useState(false)

  useEffect(()=>{
     if (!isLoggedIn) {
       navigate('/LoginPage')
     }
  },[])

  useEffect(() => {
    // Prevent tab switching
    const handleTabSwitch = () => {
      if (!isAlertVisible) {
        setIsAlertVisible(true) // Set alert visibility to true
        alert('Tab switching is not allowed. Please stay on this page.')
        setTimeout(() => setIsAlertVisible(false), 1000) // Allow alerts after 1 second
      }
      window.focus() // Bring focus back to the tab
    }

    const handleVisibilityChange = () => {
      if (document.hidden && !isAlertVisible) {
        handleTabSwitch()
      }
    }

    // Attach event listeners
    window.addEventListener('blur', handleTabSwitch) // Triggered when the tab loses focus
    document.addEventListener('visibilitychange', handleVisibilityChange) // Triggered when tab visibility changes

    // Cleanup event listeners
    return () => {
      window.removeEventListener('blur', handleTabSwitch)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [isAlertVisible])

  // Restrict clipboard actions
  useEffect(() => {
    const disableClipboard = (e) => {
      if (!isAlertVisible) {
        setIsAlertVisible(true) // Set alert visibility to true
        e.preventDefault() // Block the clipboard action
        alert('Copy-pasting is disabled in this environment!')
        setTimeout(() => setIsAlertVisible(false), 1000) // Allow alerts after 1 second
      }
    }

    const handlePaste = (e) => {
      if (!isAlertVisible) {
        setIsAlertVisible(true)
        e.preventDefault()
        console.log('Paste event triggered')
        alert('Paste is disabled in this environment!')
        setTimeout(() => setIsAlertVisible(false), 1000)
      }
    }

    const editorNode = editorRef.current

    if (editorNode && editorNode.addEventListener) {
      editorNode.addEventListener('paste', handlePaste)
    }

    // Attach global clipboard listeners
    document.addEventListener('copy', disableClipboard)
    document.addEventListener('cut', disableClipboard)
    document.addEventListener('paste', handlePaste)

    // Cleanup event listeners
    return () => {
      if (editorNode && editorNode.removeEventListener) {
        editorNode.removeEventListener('paste', handlePaste)
      }
      document.removeEventListener('copy', disableClipboard)
      document.removeEventListener('cut', disableClipboard)
      document.removeEventListener('paste', handlePaste)
    }
  }, [isAlertVisible])


  useEffect(() => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen()
    }
  }, [])

  // Fetch user ID based on username
  useEffect(() => {
    const fetchUserId = async () => {
      const username = localStorage.getItem('username') // Assuming the username is stored in localStorage
      try {
        const data = await apiClient('quiz/users/', 'GET')

        const user = data.find((user) => user.username === username)
        if (user) {
          setUserId(user.id)
        } else {
          setError('User not found.')
        }
      } catch (err) {
        setError('Error fetching user data.')
      }
    }

    fetchUserId()
  }, [])

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const data = await apiClient('compiler/languages/', 'GET')

        if (Array.isArray(data)) {
          setLanguages(data)
        } else {
          setError('Unexpected data format.')
        }
      } catch (err) {
        setError('Error fetching languages.')
      }
    }

    fetchLanguages()
  }, [])

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang)
    switch (lang) {
      case 'Java':
        setSourceCode(`import java.io.*;
import java.util.*;

public class Main {

    public static void main(String[] args) {
        /* Enter your code here. */
    }
}`)
        break
      case 'python':
        setSourceCode('# Write your Python code here')
        break
      case 'C++':
        setSourceCode(`#include <iostream>

int main() {
    // Write C++ code here
    std::cout << "Hello, World!";
    return 0;
}`)
        break
      default:
        setSourceCode('')
        break
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsRunClicked(true)

    const requestData = {
      source_code: sourceCode,
      language: selectedLanguage,
      expected_output: expectedOutput,
      question_id: location.state.question.id,
      user_id: userId, // Use dynamic user ID
    }

    try {
      const response = await apiClient(
        'compiler/run-test/',
        'POST',
        requestData
      )

      if (
        response.passed_cases !== undefined &&
        response.total_cases !== undefined
      ) {
        setPassedCases(response.passed_cases)
        setTotalCases(response.total_cases)
        setIsEditorLocked(true)
        setShowTestCases(true)
        setShowInputOutput(false)
      } else {
        setError('Unexpected response format.')
      }

      if (response.output === expectedOutput) {
        setResult('Answer correct')
      } else {
        setResult('Answer wrong')
      }

      // Pass scored points and other data to the Dashboard page
    //   navigate('/Dashboard', {
    //     state: {
    //       passedCases: response.passed_cases,
    //       totalCases: response.total_cases,
    //       result:
    //         response.output === expectedOutput
    //           ? 'Answer correct'
    //           : 'Answer wrong',
    //       output: response.output,
    //       expectedOutput: expectedOutput,
    //       score: response.passed_cases, // Passed test cases as score
    //       date: new Date().toISOString().split('T')[0], // Current date (YYYY-MM-DD format)
    //     },
    //   })
    } catch (err) {
      console.error('Error during submission:', err)
      setError('Error submitting the answer. Please try again.')
    }
  }

  const handleCompile = async () => {
    const requestData = {
      source_code: sourceCode,
      language: selectedLanguage,
      stdin: stdin,
    }

    try {
      const compileResponse = await apiClient(
        'compiler/compile/',
        'POST',
        requestData
      )

      if (compileResponse.error) {
        setOutput(compileResponse.error)
        setIsSubmitDisabled(true)
        return
      }

      setOutput(compileResponse.output || 'Compiled successfully!')
      setIsSubmitDisabled(false)
    } catch (err) {
      console.error('Error during compilation:', err)
      setError('Error compiling the code. Please try again.')
      setIsSubmitDisabled(true)
    }
  }

  const toggleInputOutput = () => {
    setShowInputOutput((prev) => !prev)
    setShowTestCases(false)
  }

  const handleEditorWillMount = (monaco) => {
    // Add a command to intercept paste
    monaco.editor.defineTheme('vs-dark', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': '#1E1E1E',
      },
    })
  }

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor

    // Intercept paste events
    editor.onDidPaste((e) => {
      alert('Pasting is disabled in this editor!')
      // No further action; paste is effectively blocked
      editor.getModel().undo() // Undo the last action, effectively canceling the paste
    })

    // Disable paste via command
    editor.addCommand(
      monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_V, // Ctrl+V or Command+V
      () => {
        alert('Pasting is disabled in this editor!')
        return null
      }
    )

    // Block mouse right-click paste (context menu paste)
    editor.onKeyDown((event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        event.keyCode === monaco.KeyCode.KEY_V
      ) {
        alert('Pasting is disabled in this editor!')
        event.preventDefault()
      }
    })

    // Disable context menu paste
    editor.onDidPaste(() => {
      // alert('Pasting is disabled in this editor!')
    })

     // Block context menu paste (right-click + paste)
    editor.addAction({
      id: "disable-paste",
      label: "Paste is disabled",
      keybindings: [
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_V, // Prevent Ctrl+V
        monaco.KeyCode.F10, // Context menu paste
      ],
      contextMenuGroupId: "navigation",
      contextMenuOrder: 1,
      run: () => {
        alert("Pasting is disabled in this editor!");
        return null;
      },
    });
  }


  return (
    <>
      {/* <Navbar 
            isLoggedIn={isLoggedIn} 
            username={username} 
            userRole={userRole} 
            handleLogout={handleLogout} 
          /> */}
      <Container fluid className="question-page">
        <Row className="d-flex" style={{ height: '100%' }}>
          <Col md={3} className="description-column d-flex flex-column">
            <h4>
              <b>Description :</b>
            </h4>
            <p>{location.state.question.question}</p>
          </Col>

          <Col
            md={showInputOutput || showTestCases ? 6 : 9}
            className="code-column d-flex flex-column"
          >
            <div className="code-header d-flex justify-content-between align-items-center">
              <Dropdown>
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  name="lan"
                >
                  {selectedLanguage || 'Select Language'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {languages.length > 0 ? (
                    languages.map((langObj) => (
                      <Dropdown.Item
                        key={langObj.id}
                        onClick={() => handleLanguageChange(langObj.language)}
                      >
                        {langObj.language}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item disabled>Loading languages...</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <Button variant="success" onClick={handleSubmit}>
                Run
              </Button>
              <Button variant="success" onClick={toggleInputOutput}>
                Custom Input/Output
              </Button>
            </div>

            <MonacoEditor
              height="600px"
              language={
                selectedLanguage ? selectedLanguage.toLowerCase() : 'plaintext'
              }
              value={sourceCode}
              onChange={(value) => setSourceCode(value)}
              beforeMount={handleEditorWillMount}
              onMount={handleEditorDidMount}
              options={{
                selectOnLineNumbers: true,
                theme: 'vs-dark',
                automaticLayout: true,
                minimap: { enabled: false },
                readOnly: isEditorLocked,
                scrollbar: {
                  verticalScrollbarSize: 0,
                  horizontalScrollbarSize: 0,
                },
                padding: { top: 0, bottom: 0 },
              }}
            />
          </Col>

          {showInputOutput && (
            <Col md={3} className="input-output-column">
              <Form.Group controlId="stdinInput" className="custom-input-box">
                <Form.Label>Input</Form.Label>
                <div
                  className="input-with-button"
                  style={{ position: 'relative' }}
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={stdin}
                    onChange={(e) => setStdin(e.target.value)}
                    className="custom-textarea"
                    style={{
                      backgroundColor: 'black',
                      color: 'white',
                      border: '1px solid gray',
                      borderRadius: '5px',
                      resize: 'none',
                      paddingRight: '70px',
                    }}
                  />
                  <Button
                    variant="success"
                    onClick={handleCompile}
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      padding: '5px 10px',
                      fontSize: '0.85rem',
                      height: '30px',
                      lineHeight: '1rem',
                    }}
                  >
                    Compile
                  </Button>
                </div>
              </Form.Group>

              <Form.Group controlId="outputInput">
                <Form.Label>Output</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={output}
                  readOnly
                  className="custom-textarea"
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    border: '1px solid gray',
                    borderRadius: '5px',
                    resize: 'none',
                  }}
                />
              </Form.Group>
            </Col>
          )}
          {showTestCases && (
            <Col md={3} className="input-output-column">
              <Form.Group
                controlId="testCaseResults"
                className="custom-input-box"
              >
                <Form.Label>Test Case Results</Form.Label>
                <div className="test-case-results">
                  <ul
                    style={{
                      display: 'flex',
                      listStyleType: 'none',
                      padding: 0,
                    }}
                  >
                    {Array.from({ length: totalCases }, (_, index) => (
                      <li key={index} style={{ margin: '0 10px' }}>
                        {index < passedCases ? (
                          <span
                            style={{
                              display: 'inline-block',
                              width: '30px',
                              height: '30px',
                              borderRadius: '50%',
                              backgroundColor: 'green',
                              color: 'white',
                              textAlign: 'center',
                              lineHeight: '30px',
                              fontSize: '18px',
                            }}
                          >
                            ✓
                          </span>
                        ) : (
                          <span
                            style={{
                              display: 'inline-block',
                              width: '30px',
                              height: '30px',
                              borderRadius: '50%',
                              backgroundColor: 'red',
                              color: 'white',
                              textAlign: 'center',
                              lineHeight: '30px',
                              fontSize: '18px',
                            }}
                          >
                            ✕
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                  <Button variant="success" href="/Dashboard">
                    Submit
                  </Button>
                </div>
              </Form.Group>
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
};

export default QuestionPage;