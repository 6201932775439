import React, { useEffect, useState } from 'react';
import MCQQuiz from './MCQQuiz';
import apiClient from './utils/apiClient';
import { useLocation, useNavigate } from 'react-router-dom';



const apiEndpoint = 'https://your-api-endpoint.com/submit-answers';

const McqTestPage = () => {

  const {state} = useLocation();
  const {isLoggedIn} = useLocation();
  const {subtype} = state || {}
  const navigate = useNavigate()
  const [questions, setQuestion] = useState([
    { id: 1, question: 'Which of the following is used to declare a variable in Python?', options: '[var a, let a, a = 10, int a = 10]' },
    { id: 2, question: 'Which keyword is used to define a global variable in Python?', options: '[global, static, let, const]' },
    // Add more questions here
  ]);
  
  useEffect(() => {
    //  if (!isLoggedIn) {
    //    navigate('/LoginPage')
    //  }
    const fetchQuestions = async () => {
      try {
        const data = await apiClient(`quiz/questions/?subtype=${subtype}`, 'GET')
        if (Array.isArray(data)) {
          const orderedQuestions = data.sort(
            (a, b) => a.question.id - b.question.id
          )
          console.log("orderqs", orderedQuestions);
          setQuestion(orderedQuestions)
          setTotalQuestions(orderedQuestions.length)
        } else {
          console.error('Unexpected data format:', data)
        }
      } catch (error) {
        console.error('Error fetching MCQ data:', error)
      }
    }
  
    
  
    fetchQuestions()
  
  
  
  }, [])


  return <MCQQuiz questions={questions} apiEndpoint={apiEndpoint} />;
};

export default McqTestPage;
