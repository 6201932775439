import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import './LoginPage.css'
import loginImage from './images/signup1.avif' //Replace with your login image path
import apiClient from './utils/apiClient';

const LoginPage = ({ setIsLoggedIn, setUsername }) => {
  const [username, setLocalUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    try {
      // Use apiClient instead of axios.post
      const response = await apiClient('quiz/users/login/', 'POST', {
        email: username,
        password: password,
      });
  
      setSuccess('Login successful!');
      setUsername(username);
      setIsLoggedIn(true);
  
      // Save username to localStorage
      localStorage.setItem('username', username);
      let userid = response.user_id;
      localStorage.setItem('userid', userid);
  
      // Navigate to dashboard
      navigate('/UserDashboard');
    } catch (error) {
      console.error(error);
      setError('Invalid username or password. Please try again.');
    }
  };

  // Inline styles for LoginPage
  const styles = {
    container: {
      marginTop: '2rem',
      padding: '3rem',
      background: 'rgba(255, 255, 255, 0.8)',
      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
      borderRadius: '20px',
    },
    heading: {
      textAlign: 'center',
      marginBottom: '2rem',
      fontWeight: 'bold',
      fontSize: '2.5rem',
    },
    span: {
      color: '#ffa003',
    },
    label: {
      color: '#ffa003',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    formGroup: {
      marginBottom: '1rem',
    },
    formControl: {
      height: '45px',
      borderRadius: '8px',
    },
    button: {
      width: '100%',
      padding: '10px',
      backgroundColor: '#ffa003',
      border: 'none',
      color: '#ffffff',
      fontWeight: 'bold',
      borderRadius: '8px',
      fontSize: '1rem',
    },
    alert: {
      textAlign: 'center',
      marginBottom: '1rem',
    },
    leftImage: {
      width: '100%',
      borderRadius: '15px',
    },
  }

  return (
    <Container>
      <Row className="align-items-center">
        {/* Left Image Section */}
        <Col md={6} className="d-flex justify-content-center">
          <img src={loginImage} alt="Login" style={styles.leftImage} />
        </Col>

        {/* Right Login Form */}
        <Col md={6}>
          <div style={styles.container}>
            <h1 style={styles.heading}>
              <span style={styles.span}>Log</span> In
            </h1>
            {error && (
              <Alert style={styles.alert} variant="danger">
                {error}
              </Alert>
            )}
            {success && (
              <Alert style={styles.alert} variant="success">
                {success}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Username</Form.Label>
                <Form.Control
                  style={styles.formControl}
                  type="text"
                  value={username}
                  onChange={(e) => setLocalUsername(e.target.value)}
                  placeholder="Enter your username"
                  required
                />
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Password</Form.Label>
                <Form.Control
                  style={styles.formControl}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
              </Form.Group>

              <Button style={styles.button} type="submit">
                Login
              </Button>
            </Form>
            <div className="text-center mt-3">
              <span>Don't have an account? </span>
              <Button
                variant="link"
                onClick={() => navigate('/SignUp')}
                style={{
                  color: '#ffa003',
                  textDecoration: 'none',
                  fontWeight: 'bold',
                }}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginPage
