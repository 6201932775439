import React, { useEffect, useState, useRef  } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Slider,
  IconButton,
  Checkbox,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
import LanguageIcon from '@mui/icons-material/Language'
import axios from 'axios'
import Navbar from './Navbar'

const validationSchema = yup.object({
  name: yup.string().required('Full name is required'),
  dob: yup.date().required('Date of birth is required'),
  current_location: yup.string().required('Current location is required'),
  native_location: yup.string().required('Native location is required'),
  education: yup
    .array()
    .of(
      yup.object({
        degree: yup.string().required('Degree is required'),
        year: yup
          .number()
          .typeError('Year must be a number')
          .required('Year is required'),
        institution: yup.string().required('Institution is required'),
      })
    )
    .required(),
  experience: yup
    .array()
    .of(
      yup.object({
        role: yup.string().required('Role is required'),
        company: yup.string().required('Company is required'),
        years: yup
          .number()
          .typeError('Years must be a number')
          .required('Years are required'),
      })
    )
    .required(),
  skills: yup
    .array()
    .of(
      yup.object({
        name: yup.string().required('Skill name is required'),
        rating: yup.number().required('Rating is required'),
      })
    )
    .required(),
  projects: yup
    .array()
    .of(
      yup.object({
        title: yup.string().required('Project title is required'),
        description: yup.string().required('Project description is required'),
      })
    )
    .required(),
  declaration: yup.bool().oneOf([true], 'You must accept the declaration'),
})

const steps = [
  'Personal Info',
  'Education',
  'Experience',
  'Skills',
  'Projects',
  'Resume',
]


export default function TrainerFormFullScreen({
  isLoggedIn,
  username,
  userRole,
  handleLogout,
}) {
  // Add default project values in useForm
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      dob: '',
      current_location: '',
      native_location: '',
      profilePicture: null,
      education: [{ degree: '', year: '', institution: '' }],
      experience: [{ role: '', company: '', years: '' }],
      trainingHistory: [
        { company: '', eventPlace: '', programTitle: '', audience: '' },
      ],
      skills: [{ name: '', rating: 50 }],
      projects: [
        {
          title: '',
          description: '',
          repoLink: '',
          deployLink: '',
          thumbnail: null,
        },
      ],
    },
  })

  const {
    fields: educationFields,
    append: addEducation,
    remove: removeEducation,
  } = useFieldArray({ control, name: 'education' })

  const {
    fields: experienceFields,
    append: addExperience,
    remove: removeExperience,
  } = useFieldArray({ control, name: 'experience' })

  const {
    fields: skillsFields,
    append: addSkill,
    remove: removeSkill,
  } = useFieldArray({
    control,
    name: 'skills',
  })

  // Field Array for Projects
  const {
    fields: projectFields,
    append: addProject,
    remove: removeProject,
  } = useFieldArray({
    control,
    name: 'projects',
  })

  // Handle Thumbnail Upload
  const handleThumbnailUpload = (e, index) => {
    const file = e.target.files[0]
    if (file) {
      // Update the project field with the thumbnail file
      projectFields[index].thumbnail = file
    }
  }

  const [step, setStep] = useState(0)
  const [image, setImage] = useState(null)
  const stepRefs = useRef([])
  const containerRef = useRef(null)
  const dynamicMarginTop = () => {
    switch (step) {
      case 0:
        return '150px'
      case 1:
        return 'auto'
      case 2:
        return 'auto'
      case 3:
        return 'auto'
      case 4:
        return '60px'
      case 5:
        return '60px'
      default:
        return 'auto'
    }
  }
  const stepValidationFields = {
    0: ['name', 'dob', 'current_location', 'native_location'], // Fields for Step 0
    1: ['education'], // Fields for Step 1
    2: ['experience'], // Fields for Step 2
    3: ['skills'], // Fields for Step 3
    4: ['projects'], // Fields for Step 4
    5: ['resume', 'declaration'], // Fields for Step 5
  }

  const handleImageUpload = (e) => setImage(e.target.files[0])

  const onSubmit = async (data, event) => {
    event.preventDefault()

   const payload = {
  name: data.name, // Full Name
  dob: data.dob ? new Date(data.dob).toISOString().split('T')[0] : null, // Date of Birth in YYYY-MM-DD format (Ensure valid date)
  current_location: data.current_location, // Current Location
  native_location: data.native_location, // Native Location
  profilePicture: image || null, // Profile Picture (Base64 or file path)
  linkedin: data.linkedin || null, // LinkedIn URL
  github: data.github || null, // GitHub URL
  portfolio: data.portfolio || null, // Portfolio Website URL
  education: data.education.map((edu) => ({
    degree: edu.degree, // Degree
    year: edu.year, // Year
    institution: edu.institution, // Institution Name
  })),
  experience: data.experience.map((exp) => ({
    role: exp.role, // Role/Position
    company: exp.company, // Company Name
    years: exp.years, // Number of Years
  })),
  skills: data.skills && data.skills.length > 0 ? data.skills.map((skill) => ({
    name: skill.name, // Skill Name
    rating: skill.rating, // Skill Rating (Slider Value)
  })) : [], // Ensure the skills array is never undefined or empty
  projects: data.projects && data.projects.length > 0 ? data.projects.map((project) => ({
    title: project.title, // Project Title
    description: project.description, // Project Description
    repoLink: project.repoLink || null, // GitHub Repository Link
    deployLink: project.deployLink || null, // Deployment Link
    thumbnail: project.thumbnail || null, // Thumbnail Image (Base64 or file path)
  })) : [], // Ensure the projects array is never undefined or empty
  resume: data.resume || null, // Uploaded Resume (Base64 or file path)
  declaration: data.declaration || false, // Declaration Agreement (Checkbox Value)
};


   try {
     const response = await apiClient(
       'trainer/trainers/', // Endpoint
       'POST', // HTTP method
       payload, // Request body
       { 'Content-Type': 'application/json' } // Custom headers
     )

     console.log('Form submitted:', response)
     alert('Trainer profile submitted successfully!')
     reset() // Reset the form on successful submission
   } catch (error) {
     console.error('Form submission failed:', error)
     alert('Form submission failed. Please try again.')
   }

  }

  const nextStep = async () => {
    const currentFields = stepValidationFields[step]
    // Validate fields for the current step only
    const isStepValid = await trigger(currentFields)

    if (isStepValid) {
      setStep((prevStep) => prevStep + 1)
    } else {
      console.error('Validation failed for step:', step)
    }
  }

  const prevStep = () => setStep((prevStep) => prevStep - 1)
  const [resumeFile, setResumeFile] = useState(null)
  const [declarationChecked, setDeclarationChecked] = useState(false)

  const handleResumeUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      setResumeFile(file)
      console.log('Uploaded file:', file)
    } else {
      console.error('No file selected')
    }
  }

  const handleDeclarationChange = (event) => {
    setDeclarationChecked(event.target.checked)
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/LoginPage')
    }
  }, [])

   useEffect(() => {
     // Ensure the active step is scrolled into view
     if (stepRefs.current[step]) {
       stepRefs.current[step].scrollIntoView({
         behavior: 'smooth',
         block: 'nearest',
         inline: 'center', // Center the step horizontally
       })
     }

     // If on 0th step, ensure the container is scrolled to the start
     if (step === 0 && containerRef.current) {
       containerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
     }
   }, [step])

  const isFormValid = resumeFile && declarationChecked // Validate both file upload and declaration checkbox

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Box
              position="relative"
              padding={2}
              border="1px solid #ddd"
              borderRadius="8px"
              boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)"
              bgcolor="white"
            >
              <TextField
                fullWidth
                label="Full Name"
                {...register('name', { required: 'Full name is required' })}
                error={!!errors.name}
                helperText={errors.name?.message}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...register('dob', { required: 'Date of birth is required' })}
                error={!!errors.dob}
                helperText={errors.dob?.message}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Current Location"
                {...register('current_location', {
                  required: 'current location is required',
                })}
                error={!!errors.current_location}
                helperText={errors.current_location?.message}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Native Location"
                {...register('native_location', {
                  required: 'Native location is required',
                })}
                error={!!errors.native_location}
                helperText={errors.native_location?.message}
                margin="normal"
              />
              <Typography variant="h6" marginTop={2}>
                Profile Picture
              </Typography>
              <TextField
                fullWidth
                type="file"
                InputLabelProps={{ shrink: true }}
                onChange={handleImageUpload}
                margin="normal"
              />

              {/* LinkedIn URL */}
              <Box display="flex" alignItems="center" marginTop={2}>
                <LinkedInIcon style={{ marginRight: '8px' }} color="primary" />
                <TextField
                  fullWidth
                  label="LinkedIn URL"
                  {...register('linkedin')}
                  margin="normal"
                />
              </Box>

              {/* GitHub URL */}
              <Box display="flex" alignItems="center" marginTop={2}>
                <GitHubIcon style={{ marginRight: '8px' }} />
                <TextField
                  fullWidth
                  label="GitHub URL"
                  {...register('github')}
                  margin="normal"
                />
              </Box>

              {/* Portfolio Website URL */}
              <Box display="flex" alignItems="center" marginTop={2}>
                <LanguageIcon
                  style={{ marginRight: '8px' }}
                  color="secondary"
                />
                <TextField
                  fullWidth
                  label="Portfolio Website URL"
                  {...register('portfolio')}
                  margin="normal"
                />
              </Box>
            </Box>
          </>
        )

      // Other steps remain unchanged

      case 1:
        return (
          <>
            <Grid container spacing={2}>
              {educationFields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                  <Box
                    position="relative"
                    padding={2}
                    border="1px solid #ddd"
                    borderRadius="8px"
                    boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)"
                    bgcolor="white"
                  >
                    <IconButton
                      onClick={() => removeEducation(index)}
                      color="error"
                      style={{
                        position: 'absolute', // Position it absolutely within the card
                        top: '10px', // Align to the top
                        right: '10px', // Align to the right
                        padding: '8px',
                        backgroundColor: 'white',
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Box marginTop="20px">
                      <TextField
                        // style={{ paddingTop: '20px' }}
                        fullWidth
                        label="Degree"
                        {...register(`education.${index}.degree`)}
                        error={!!errors.education?.[index]?.degree}
                        helperText={errors.education?.[index]?.degree?.message}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Year"
                        type="number"
                        {...register(`education.${index}.year`)}
                        error={!!errors.education?.[index]?.year}
                        helperText={errors.education?.[index]?.year?.message}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Institution"
                        {...register(`education.${index}.institution`)}
                        error={!!errors.education?.[index]?.institution}
                        helperText={
                          errors.education?.[index]?.institution?.message
                        }
                        margin="normal"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box marginTop={2} textAlign="center">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                  addEducation({ degree: '', year: '', institution: '' })
                }
              >
                Add Education
              </Button>
            </Box>
          </>
        )

      case 2:
        return (
          <>
            <Grid container spacing={2}>
              {experienceFields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                  <Box
                    position="relative"
                    padding={2}
                    border="1px solid #ddd"
                    borderRadius="8px"
                    boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)"
                    bgcolor="white"
                  >
                    <IconButton
                      onClick={() => removeExperience(index)}
                      color="error"
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        padding: '8px',
                        backgroundColor: 'white',
                        // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                        // borderRadius: '50%',
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Box marginTop="20px">
                      <TextField
                        fullWidth
                        label="Role"
                        {...register(`experience.${index}.role`)}
                        error={!!errors.experience?.[index]?.role}
                        helperText={errors.experience?.[index]?.role?.message}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Company"
                        {...register(`experience.${index}.company`)}
                        error={!!errors.experience?.[index]?.company}
                        helperText={
                          errors.experience?.[index]?.company?.message
                        }
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Years"
                        type="number"
                        {...register(`experience.${index}.years`)}
                        error={!!errors.experience?.[index]?.years}
                        helperText={errors.experience?.[index]?.years?.message}
                        margin="normal"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box marginTop={2} textAlign="center">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                  addExperience({ role: '', company: '', years: '' })
                }
              >
                Add Experience
              </Button>
            </Box>
          </>
        )

      case 3:
        return (
          <>
            <Grid container spacing={2}>
              {skillsFields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                  <Box
                    position="relative"
                    padding={2}
                    border="1px solid #ddd"
                    borderRadius="8px"
                    boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)"
                    bgcolor="white"
                  >
                    <IconButton
                      onClick={() => removeSkill(index)}
                      color="error"
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        padding: '8px',
                        backgroundColor: 'white',
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Box marginTop="20px">
                      <TextField
                        fullWidth
                        label="Skill Name"
                        {...register(`skills.${index}.name`)}
                        error={!!errors.skills?.[index]?.name}
                        helperText={errors.skills?.[index]?.name?.message}
                        margin="normal"
                      />
                      <Box marginTop={2}>
                        <Typography gutterBottom>Rate Your Skill</Typography>
                        <Slider
                          valueLabelDisplay="auto"
                          defaultValue={50}
                          onChange={(e, value) =>
                            register(`skills.${index}.rating`).onChange({
                              target: { value },
                            })
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box marginTop={2} textAlign="center">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => addSkill({ name: '', rating: 50 })}
              >
                Add Skill
              </Button>
            </Box>
          </>
        )

      case 4: // Projects Step
        return (
          <>
            <Grid container spacing={2}>
              {projectFields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                  <Box
                    position="relative"
                    padding={2}
                    border="1px solid #ddd"
                    borderRadius="8px"
                    boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)"
                    bgcolor="white"
                  >
                    <IconButton
                      onClick={() => removeProject(index)}
                      color="error"
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        padding: '8px',
                        backgroundColor: 'white',
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Box marginTop="20px">
                      <TextField
                        fullWidth
                        label="Project Title"
                        {...register(`projects.${index}.title`)}
                        error={!!errors.projects?.[index]?.title}
                        helperText={errors.projects?.[index]?.title?.message}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        multiline
                        label="Project Description"
                        {...register(`projects.${index}.description`)}
                        error={!!errors.projects?.[index]?.description}
                        helperText={
                          errors.projects?.[index]?.description?.message
                        }
                        margin="normal"
                      />
                      <Box display="flex" alignItems="center" marginTop={2}>
                        <GitHubIcon style={{ marginRight: '8px' }} />
                        <TextField
                          fullWidth
                          label="Repo Link"
                          {...register(`projects.${index}.repoLink`)}
                        />
                      </Box>
                      <Box display="flex" alignItems="center" marginTop={2}>
                        <LanguageIcon style={{ marginRight: '8px' }} />
                        <TextField
                          fullWidth
                          label="Deploy Link"
                          {...register(`projects.${index}.deployLink`)}
                        />
                      </Box>
                      <Box marginTop={2}>
                        <Typography variant="subtitle1">
                          Upload Thumbnail
                        </Typography>
                        <TextField
                          fullWidth
                          type="file"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handleThumbnailUpload(e, index)}
                          margin="normal"
                        />
                      </Box>
                      {projectFields[index]?.thumbnail && (
                        <Box
                          component="img"
                          src={URL.createObjectURL(
                            projectFields[index].thumbnail
                          )}
                          alt="Thumbnail Preview"
                          width="100%"
                          height="auto"
                          borderRadius="8px"
                          marginTop={2}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box marginTop={2} textAlign="center">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                  addProject({
                    title: '',
                    description: '',
                    repoLink: '',
                    deployLink: '',
                    thumbnail: null,
                  })
                }
              >
                Add Project
              </Button>
            </Box>
          </>
        )
      case 5:
        return (
          <>
            <Box
              position="relative"
              padding={2}
              border="1px solid #ddd"
              borderRadius="8px"
              boxShadow="0 2px 8px rgba(0, 0, 0, 0.1)"
              bgcolor="white"
            >
              <Typography variant="h6" marginBottom={2}>
                Upload Resume
              </Typography>
              <TextField
                fullWidth
                type="file"
                InputLabelProps={{ shrink: true }}
                {...register('resume', {
                  required: 'Resume upload is required',
                })}
                error={!!errors.resume}
                helperText={errors.resume?.message}
                onChange={handleResumeUpload} // Replace with your resume upload handler
                margin="normal"
              />

              <Box marginTop={4}>
                <Typography variant="subtitle1">Declaration</Typography>
                <Box display="flex" alignItems="center" marginTop={2}>
                  <Checkbox
                    {...register('declaration', { required: true })}
                    color="primary"
                  />
                  <Typography variant="body2">
                    I hereby declare that the above information is true to the
                    best of my knowledge.
                  </Typography>
                </Box>
                {errors.declaration && (
                  <Typography color="error" variant="caption">
                    {errors.declaration?.message}
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )

      default:
        return null
    }
  }

  return (
    <div>
    <Navbar
      isLoggedIn={isLoggedIn}
      username={username}
      userRole={userRole}
      handleLogout={handleLogout}
    />
  
    {/* Stepper Component */}
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: '16px',
        bgcolor: 'white', // Optional: Highlight background
        position: 'sticky', // Keep stepper visible at the top
        top: 0, // Position at the top
        zIndex: 10, // Ensure stepper is above other content
      }}
    >
      <Stepper
        activeStep={step}
        alternativeLabel
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          scrollbarWidth: 'auto', // Enable scrolling for smaller screens
          '& .MuiStep-root': {
            flex: '1 1 auto',
            textAlign: 'center',
            minWidth: '120px',
          },
          '& .MuiStepLabel-label': {
            fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
          },
          '& .MuiStepConnector-line': {
            display: 'block',
          },
        }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  
    {/* Main Form */}
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      minHeight="100vh"
      padding={4}
      sx={{
        marginTop: '16px',
      }}
    >
      <Typography variant="h4" gutterBottom>
        User Form
      </Typography>
  
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: '100%',
          maxWidth: '800px',
          marginTop: '16px',
          padding: '16px',
          background: 'white',
          borderRadius: '8px',
        }}
      >
        {/* Render Form Content */}
        {renderStepContent()}
  
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={step === 0}
            onClick={prevStep}
          >
            Back
          </Button>
          {step < steps.length - 1 ? (
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={nextStep}
            >
              Next
            </Button>
          ) : (
            <Button type="submit" variant="contained" color="success">
              Submit
            </Button>
          )}
        </Box>
      </form>
    </Box>
  </div>
  
  )
}
