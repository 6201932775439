// LogoSection.js
import React from 'react';
import Info from './images/infosys-logo-jpeg.png';
import Capgemini from './images/Capgemini-Logo.png';
import Tcs from './images/tcs_logo_1200_020621101143.png';
import Google from './images/google2.0.0.1441125613.png';
import HP from './images/1200px-HP_logo_2012.svg.png';
import IBM from './images/416_ibm.png';
import Microsoft from './images/Microsoft logo.png';
import Amazon from './images/amazon logo.png';
import vitnezt from './images/vitnezt.png';
import s2s from './images/s2s.png';
import olir from './images/olir.png';
import gts from './images/gts.jpg';
import nurture from './images/nurture.svg';
import sagent from './images/sagent.png';
import mrcooper from './images/mrcooper.png';
import epam from './images/epam.png';

import './LogoSection.css'; // Assuming you will create a CSS file for styling

const LogoSection = () => {
  return (
    <div className="logos-container">
      <div className="logos-wrapper">
        <div className="logos">
          <img src={Info} alt="Infosys" />
          <img src={Capgemini} alt="Capgemini" />
          <img src={Tcs} alt="TCS" />
          <img src={Google} alt="Google" />
          <img src={HP} alt="HP" />
          <img src={IBM} alt="IBM" />
          <img src={Microsoft} alt="Microsoft" />
          <img src={Amazon} alt="Amazon" />
          <img src={vitnezt} alt="vitnezt" />
          <img src={olir} alt="olir" />
          <img src={s2s} alt="s2s" />
          <img src={gts} alt="gts" />
          <img src={nurture} alt="nurture" />
          <img src={epam} alt="epam" />
          <img src={mrcooper} alt="mrcooper" />
          <img src={sagent} alt="sagent" />
        </div>
        {/* Duplicate logos for seamless scrolling */}
        <div className="logos">
          <img src={Info} alt="Infosys" />
          <img src={Capgemini} alt="Capgemini" />
          <img src={Tcs} alt="TCS" />
          <img src={Google} alt="Google" />
          <img src={HP} alt="HP" />
          <img src={IBM} alt="IBM" />
          <img src={Microsoft} alt="Microsoft" />
          <img src={Amazon} alt="Amazon" />
          <img src={vitnezt} alt="vitnezt" />
          <img src={olir} alt="olir" />
          <img src={s2s} alt="s2s" />
          <img src={gts} alt="gts" />
          <img src={nurture} alt="nurture" />
          <img src={epam} alt="epam" />
          <img src={mrcooper} alt="mrcooper" />
          <img src={sagent} alt="sagent" />
        </div>
      </div>
    </div>
  );
};


export default LogoSection;
