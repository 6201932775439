import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Card, Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Navbar from './Navbar'
import apiClient from './utils/apiClient'
import { FaLock } from 'react-icons/fa'

const CategoryList = ({ isLoggedIn, userRole, handleLogout, username }) => {
  const [categories, setCategories] = useState([])
  const [subtypes, setSubtypes] = useState({})
  const [loadingCategories, setLoadingCategories] = useState(true)
  const [loadingSubtypes, setLoadingSubtypes] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate()


  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/LoginPage')
    }
  }, [])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await apiClient('quiz/get-category/', 'GET',)

        setCategories(data.categories || [])
      } catch (error) {
        setError(
          'Server maintenance is scheduled until 6 AM. Please try again after that'
        )
      } finally {
        setLoadingCategories(false)
      }
    }

    fetchCategories()
  }, [])


  const handleViewSubtypes = async (category) => {
    if (subtypes[category]) {
      // Collapse subtypes if already open
      setSubtypes((prev) => ({ ...prev, [category]: null }))
      return
    }

    setLoadingSubtypes(category) // Indicate loading state for the clicked category

    try {
      const response = await apiClient(
        `quiz/get-subtype/?category=${category}`,
        'GET'
      )

      setSubtypes((prev) => ({
        ...prev,
        [category]: response.subtypes || [], // Store the fetched subtypes or an empty array if none
      }))
    } catch (error) {
      console.error(`Error fetching subtypes for ${category}:`, error)
      setError(
        `Failed to fetch subtypes for ${category}. Please try again later.`
      )
    } finally {
      setLoadingSubtypes(null) // Reset loading state
    }
  }


  const handleNavigateToMcqTestPage = (subtype) => {
    // Pass data to /McqTestPage without including it in the URL
    navigate('/McqTestPage', { state: { subtype } } , {isLoggedIn : {isLoggedIn}})
  }

  if (loadingCategories)
    return (
      <div className="text-center">
        <Spinner animation="border" variant="primary" /> Loading Categories...
      </div>
    )
  if (error)
    return (
      <div className="text-center text-danger">
        <p>{error}</p>
      </div>
    )

  const responsiveStyles = {
    cardImgHeight:
      window.innerWidth <= 576
        ? '120px'
        : window.innerWidth <= 768
        ? '140px'
        : '180px',
    cardFontSize: window.innerWidth <= 768 ? '1.1rem' : '1.25rem',
    cardTextFontSize: window.innerWidth <= 768 ? '0.9rem' : '1rem',
    buttonFontSize: window.innerWidth <= 768 ? '0.9rem' : '1rem',
  }

  return (
    <>
      <Navbar
        isLoggedIn={isLoggedIn}
        username={username}
        userRole={userRole}
        handleLogout={handleLogout}
      />
      <Container
        style={{ maxWidth: '1200px', margin: '0 auto', padding: '15px' }}
      >
        <h2 className="text-center my-4">
          Explore <span style={{ color: '#007bff' }}>Quiz Categories</span>
        </h2>
        <Row>
          {categories.map((category, index) => (
            <Col sm={12} md={6} lg={4} className="mb-4" key={index}>
              <Card
                className="category-card"
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: '15px',
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease',
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = 'scale(1.05)')
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = 'scale(1)')
                }
              >
                <Card.Img
                  variant="top"
                  src="https://img.freepik.com/premium-photo/hands-teacher-having-online-class-laptop_380164-281242.jpg?ga=GA1.1.1857534666.1690658127&semt=ais_hybrid"
                  alt={`${category} image`}
                  style={{
                    height: responsiveStyles.cardImgHeight,
                    objectFit: 'cover',
                  }}
                />
                <Card.Body>
                  <Card.Title
                    style={{
                      color: '#007bff',
                      fontSize: responsiveStyles.cardFontSize,
                      fontWeight: 'bold',
                    }}
                  >
                    {category}
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: responsiveStyles.cardTextFontSize,
                      color: '#555',
                    }}
                  >
                    Explore quizzes in the {category} category. Click to view
                    available subtypes.
                  </Card.Text>
                  <Button
                    onClick={() => handleViewSubtypes(category)}
                    style={{
                      backgroundColor: '#007bff',
                      color: 'white',
                      fontSize: responsiveStyles.buttonFontSize,
                      width: '100%',
                    }}
                  >
                    {subtypes[category] ? 'Hide Subtypes' : 'View Subtypes'}
                  </Button>
                </Card.Body>
              </Card>

              {loadingSubtypes === category && (
                <div className="text-center mt-3">
                  <Spinner animation="border" variant="secondary" />
                </div>
              )}
              {subtypes[category] && (
                <Row className="mt-3">
                  {subtypes[category].map((subtype, subIndex) => (
                    <Col sm={12} key={subIndex} className="mb-3">
                      <Card
                        style={{
                          backgroundColor: '#f9f9f9',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          borderRadius: '10px',
                          transition: 'transform 0.3s ease',
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = 'scale(1.05)')
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = 'scale(1)')
                        }
                      >
                        <Card.Body>
                          <Card.Title
                           style={{
                            filter: subIndex >= 1 ? 'blur(3.5px)' : 'none', fontWeight: 'bold', color: '#007bff'
                          }}
                          >
                            {subtype}
                          </Card.Title>
                          <Card.Text
                            style={{ filter: subIndex >= 1 ? 'blur(3.5px)' : 'none',color: '#555', fontSize: '1rem' }}
                          >
                            Dive into quizzes in the {subtype} subtype.
                          </Card.Text>
                          <Button
                          disabled={subIndex >= 1}
                            style={{
                              backgroundColor: '#28a745',
                              color: 'white',
                            }}
                            onClick={() => handleNavigateToMcqTestPage(subtype)}
                          >
                            {subIndex >= 1 ? (
                        <FaLock style={{ fontSize: '1.2em', color: 'gray' }} /> // Smaller lock icon
                      ) : (
                        'Start' // Show "Start" text for unlocked questions
                      )}
                          </Button>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}

export default CategoryList
