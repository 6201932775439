import React, { useState, useEffect } from 'react'
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import signup from './images/signup1.avif'
import api from './apiConfig'
import apiClient from './utils/apiClient'

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    phone: '',
    email: '',
    otp: '',
    organization: '',
  })
  const [otpSent, setOtpSent] = useState(false)
  const [serverOtp, setServerOtp] = useState('') // Store the OTP sent from the server
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [organizations, setOrganizations] = useState([]) // To store fetched organizations
  const navigate = useNavigate()
  console.log(api)

  // Fetch organizations data on component mount
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const data = await apiClient('trainer/organizations/', 'GET')

        console.log(data) // Log the response to inspect the data structure
        // Assuming response data is an array of organizations
        if (Array.isArray(data)) {
          setOrganizations(data) // Store fetched organizations in state
        } else {
          setError('Invalid response format for organizations.')
        }
      } catch (error) {
        setError(
          'Server maintenance is scheduled until 6 AM. Please try again after that' +
            error
        )
      }
    }

    fetchOrganizations()
  }, [])
  // Empty array ensures this runs once on mount

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
    setError('')
    setSuccess('')
  }

  // Send OTP to the user's email
  const sendOtp = async () => {
    if (!formData.email) {
      setError('Please enter your email to receive OTP')
      return
    }

    try {
      const response = await apiClient(
        'quiz/send-otp/',
        'POST',
        { email: formData.email },
        { 'Content-Type': 'multipart/form-data' }
      )
      setOtpSent(true)
      setServerOtp(response.otp) // Store OTP from backend for later verification
      setSuccess('OTP sent to your email!')
    } catch (error) {
      console.error('Error sending OTP:', error)
      setError('Failed to send OTP. Please try again.')
    }
  }

  // Submit the form data after OTP verification
  const handleSubmit = async (e) => {
    e.preventDefault()

    // Uncomment this block if OTP validation on the client side is required
    // if (formData.otp !== serverOtp) {
    //     setError("Invalid OTP. Please try again.");
    //     return;
    // }

    try {
      const response = await apiClient('quiz/create-user/', 'POST', {
        name: formData.name,
        password: formData.password,
        phone: formData.phone,
        email: formData.email,
        organization: formData.organization,
        otp: formData.otp,
      })

      if (response) {
        setSuccess('Signup Successful!')
        setTimeout(() => {
          navigate('/LoginPage') // Redirect to login page after success
        }, 2000)
      } else {
        setError('Something went wrong. Please try again.')
      }
    } catch (error) {
      console.error('Error during signup:', error)
      setError(
        'Failed to create account. Please check your details and try again.'
      )
    }
  }

  // Inline styles
  const styles = {
    container: {
      marginTop: '2rem',
      padding: '3rem',
      background: 'rgba(255, 255, 255, 0.8)',
      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
      borderRadius: '20px',
    },
    heading: {
      textAlign: 'center',
      marginBottom: '2rem',
      fontWeight: 'bold',
      fontSize: '2.5rem',
    },
    span: {
      color: '#ffa003',
    },
    label: {
      color: '#ffa003',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    formGroup: {
      marginBottom: '1rem',
    },
    formControl: {
      height: '45px',
      borderRadius: '8px',
    },
    button: {
      width: '100%',
      padding: '10px',
      backgroundColor: '#ffa003',
      border: 'none',
      color: '#ffffff',
      fontWeight: 'bold',
      borderRadius: '8px',
      fontSize: '1rem',
    },
    otpButton: {
      marginLeft: '20px',
      backgroundColor: '#ffa003',
    },
    alert: {
      textAlign: 'center',
      marginBottom: '1rem',
    },
    leftImage: {
      width: '100%',
      borderRadius: '15px',
    },
  }

  return (
    <Container>
      <Row className="align-items-center">
        {/* Left Image Section */}
        <Col md={6} className="d-flex justify-content-center">
          <img src={signup} alt="Sign Up" style={styles.leftImage} />
        </Col>

        {/* Right Sign-Up Form */}
        <Col md={6}>
          <div style={styles.container}>
            <h1 style={styles.heading}>
              <span style={styles.span}>Sign</span> Up
            </h1>
            {error && (
              <Alert style={styles.alert} variant="danger">
                {error}
              </Alert>
            )}
            {success && (
              <Alert style={styles.alert} variant="success">
                {success}
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Name</Form.Label>
                <Form.Control
                  style={styles.formControl}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Password</Form.Label>
                <Form.Control
                  style={styles.formControl}
                  type="text"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  required
                />
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Phone Number</Form.Label>
                <Form.Control
                  style={styles.formControl}
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  required
                />
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Email ID</Form.Label>
                <Form.Control
                  style={styles.formControl}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>OTP Verification</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    style={styles.formControl}
                    type="text"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    placeholder="Enter OTP"
                    required
                  />
                  <Button
                    style={{ ...styles.button, ...styles.otpButton }}
                    onClick={sendOtp}
                    disabled={otpSent}
                  >
                    {otpSent ? 'OTP Sent' : 'Send OTP'}
                  </Button>
                </div>
              </Form.Group>

              <Form.Group style={styles.formGroup}>
                <Form.Label style={styles.label}>Organization</Form.Label>
                <Form.Select
                  style={styles.formControl}
                  name="organization"
                  value={formData.organization}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select your organization</option>
                  {Array.isArray(organizations) && organizations.length > 0 ? (
                    organizations.map((org) => (
                      <option key={org.name} value={org.id}>
                        {org.name}
                      </option>
                    ))
                  ) : (
                    <option value="">No organizations available</option>
                  )}
                </Form.Select>
              </Form.Group>

              <Button style={styles.button} type="submit">
                Submit
              </Button>
              <div className="text-center mt-3">
                <span>Already have an account? </span>
                <Button
                  variant="link"
                  onClick={() => navigate('/LoginPage')}
                  style={{
                    color: '#ffa003',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Log In
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SignUp
